var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(!_vm.mounted)?_c('div',[_vm._v("Chargement ...")]):_c('div',{staticStyle:{"padding":"30px"}},[_c('v-row',{staticStyle:{"background":"rgba(0, 0, 0, 0.1)","margin":"-30px"}},[_c('CurrentTarifFFME',{attrs:{"context":{ ID_Saison: _vm.selectedSeason.id }}})],1),_c('v-row',{staticStyle:{"background":"rgba(0, 0, 0, 0.05)","margin":"30px -30px 0px -30px","height":"8vh"}},[_c('CurrentTarifLigue',{attrs:{"context":{ ID_Structure: _vm.LigueID }}})],1),_c('v-row',{staticStyle:{"background":"rgba(0, 0, 0, 0.05)","margin":"0px -30px 15px -30px"}},[(_vm.ComiteTerritorial_Id)?_c('CurrentTarifCT',{attrs:{"context":{
          ID_Structure: _vm.ComiteTerritorial_Id,
          ID_Saison: _vm.selectedSeason.id,
        }}}):_vm._e()],1),_c('v-row',{staticStyle:{"margin":"0px -30px 15px -30px"}},[_c('v-divider'),_c('div',{staticClass:"formular_access"},[_c('p',[_vm._v("Activer l'accès au formulaire d'adhésion : NON")]),_c('v-switch',{on:{"change":function($event){return _vm.modifyAdhesionOnline()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"input__label"},[_vm._v("OUI")])]},proxy:true}]),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"aria-hidden":"false"}},'v-icon',attrs,false),on),[_vm._v(" help ")])]}}])},[_c('span',[_vm._v("Possibilité de renouvellement et accès au formulaire d'adhésion en ligne")])])],1),_c('div',{staticStyle:{"width":"100%"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"color":"btn btn-primary"},on:{"click":function($event){$event.preventDefault();return _vm.redirectToLicence.apply(null, arguments)}}},[_vm._v("Voir le formulaire")])],1),_c('v-divider')],1),_c('div',{staticClass:"mb-4",staticStyle:{"margin":"0 -30px"}},[_c('ListTarif',{attrs:{"context":{
          ID_Structure: _vm.currentStructureId,
          ID_Saison: _vm.selectedSeason.id,
        }}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }