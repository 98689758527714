<template>
  <div style="width: 100%">
    <div v-if="!mounted">Chargement ...</div>

    <div v-else style="padding: 30px">
      <v-row style="background: rgba(0, 0, 0, 0.1); margin: -30px">
        <CurrentTarifFFME :context="{ ID_Saison: selectedSeason.id }" />
      </v-row>
      <v-row
        style="
          background: rgba(0, 0, 0, 0.05);
          margin: 30px -30px 0px -30px;
          height: 8vh;
        "
      >
        <CurrentTarifLigue :context="{ ID_Structure: LigueID }" />
      </v-row>
      <v-row
        style="background: rgba(0, 0, 0, 0.05); margin: 0px -30px 15px -30px"
      >
        <CurrentTarifCT
          v-if="ComiteTerritorial_Id"
          :context="{
            ID_Structure: ComiteTerritorial_Id,
            ID_Saison: selectedSeason.id,
          }"
        />
      </v-row>
      <v-row style="margin: 0px -30px 15px -30px">
        <!-- <v-col cols="4">
          <span>Activer la délivrance d'adhésion en ligne</span>
        </v-col>
          <v-col cols="4">
            <v-checkbox
                          style="width: 15%"
                          v-model="EST_AdhesionOuverte"
                          :readonly="isAction"
                        ></v-checkbox>
        </v-col> -->
        <!-- <v-btn @click.prevent="modifyAdhesionOnline()" outlined color="indigo"
          >{{ message }}
        </v-btn> -->

        <v-divider></v-divider>
        <div class="formular_access">
          <p>Activer l'accès au formulaire d'adhésion : NON</p>
          <v-switch @change="modifyAdhesionOnline()" v-model="message">
            <template v-slot:label>
              <span class="input__label">OUI</span>
            </template>
          </v-switch>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" aria-hidden="false" v-bind="attrs" v-on="on">
                help
              </v-icon>
            </template>
            <span
              >Possibilité de renouvellement et accès au formulaire d'adhésion
              en ligne</span
            >
          </v-tooltip>
        </div>
        <div style="width: 100%">
          <v-btn
            color="btn btn-primary"
            style="width: 100%;"
            @click.prevent="redirectToLicence"
            >Voir le formulaire</v-btn
          >
        </div>
        <v-divider></v-divider>
      </v-row>
      <div class="mb-4" style="margin: 0 -30px">
        <ListTarif
          :context="{
            ID_Structure: currentStructureId,
            ID_Saison: selectedSeason.id,
          }"
        ></ListTarif>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ListTarif from "@/components/Tarifs/Tarifs__List.vue";
import CurrentTarifFFME from "@/components/Tarifs/TarifsFFME_CurrentList";
import CurrentTarifCT from "@/components/Tarifs/TarifsCT_CurrentList";
import CurrentTarifLigue from "@/components/Tarifs/TarifsLigue_CurrentList";

export default {
  components: {
    ListTarif,
    CurrentTarifFFME,
    CurrentTarifCT,
    CurrentTarifLigue,
  },

  data: () => ({
    selectedSeason: null,
    mounted: false,
    idsParents: {},
    EST_AdhesionOuverte: null,
    structureInfo: {},
  }),

  async created() {
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
    await this.setData();
  },
  methods: {
    ...mapActions("structure", [
      "activateDesactivateAdhesionOnline",
      "getStructureByID",
    ]),
    async setData() {
      this.structureInfo = await this.getStructureByID(
        this.currentStructure?.STR_Structure?.id
      );
      this.mounted = true;
      this.EST_AdhesionOuverte = this.structureInfo?.EST_AdhesionOnlineOuverte;
    },
    async modifyAdhesionOnline() {
      await this.activateDesactivateAdhesionOnline({
        ID_Structure: this.currentStructure?.STR_Structure?.id,
        EST_AdhesionOnlineOuverte: !this.EST_AdhesionOuverte,
      });
      await this.setData();
    },

    redirectToLicence() {
      window.open(
        `${process.env.VUE_APP_FRONT_URL}licences-dematerialise/` +
          this.currentStructure?.STR_Structure?.id
      );
    },
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "currentStructure"]),
    ...mapGetters("saisons", ["saisons"]),

    saisonPrepaClub() {
      return this.saisons.find((saison) => saison.ID_EtapeSaison == 3);
    },

    ComiteTerritorial_Id() {
      return this.currentStructure.STR_Structure.ID_ComiteTerritorial;
    },
    LigueID() {
      return this.currentStructure.STR_Structure.ID_Ligue;
    },
    message() {
      if (!this.EST_AdhesionOuverte) return false;
      return true;
    },
  },
};
</script>
<style lang="scss">
.theme--light.v-divider {
  border-color: gray !important;
  border-width: 2px !important;
  height: 100%;
  width: 100%;
}
.formular_access {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  p {
    font-family: Roboto;
    font-size: 16px;
  }
}
.input__label {
  font-family: Roboto;
  color: black;
}
.v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-left: 10px !important;
  padding-bottom: 10px !important;
}
.v-icon.v-icon {
  align-items: unset !important;
}
</style>
