<template>
  <div style="width: 100%; overflow: hidden;" v-if="mounted">
    <v-row class="px-4">
      <v-col>
        <div class="formular_access">
          <h5>Tarifs cotisation club</h5>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" aria-hidden="false" v-bind="attrs" v-on="on">
                help
              </v-icon>
            </template>
            <span
              >Seule la cotisation club est à renseigner. Les cotisations CT,
              ligue et nationale seront ajoutées automatiquement</span
            >
          </v-tooltip>
        </div>
        <!-- <h6>{{ SaisonName }}</h6> -->
        <InputSelect
          v-model="saisonSelected"
          :items="saisons"
          item_text="name"
          item_value="value"
          rules="required"
          class="label__saison"
          @input="onChangeSaison"
        />
        <v-btn
          v-if="!currentTarifs.length && tarifsLastSaison.length"
          @click="openModalConfirm"
          class="btn btn-primary"
          small
          >Copier les tarifs de la saison précedente</v-btn
        >
      </v-col>
      <v-col class="text-right">
        <!-- v-if="context.ID_Saison === saisonSelected" -->
        <v-btn
          class="mx-2"
          fab
          small
          dark
          color="indigo"
          @click.prevent="createTarif"
        >
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="currentTarifs"
      item-key="id"
      class="elevation-1 list__table"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="green"
              outlined
              @click.prevent="editTarif(item)"
              key="edit"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Modifier le tarif</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              v-on="on"
              v-bind="attrs"
              fab
              x-small
              color="error"
              outlined
              @click.prevent="deleteTarif(item)"
              key="delete"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Supprimer le tarif</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <modalConfirm
      :showDialog="modalConfirmIsOpen"
      @answerYes="copyTarif"
      text="Copier les tarifs de la saison précedente pour la saison actuelle ?"
      title=" "
      text_yess="Copier les tarifs"
      text_no="Annuler"
      @answerNo="modalConfirmIsOpen = false"
    ></modalConfirm>

    <tarifModal
      v-if="!isEdit"
      :readOnly="false"
      :modalIsOpen="tarifModalIsOpen"
      :context="context"
      @onUpdated="tarifCreated"
      @onClose="closeModal"
      key="creation"
    />
    <tarifModal
      v-if="isEdit"
      :readOnly="true"
      :modalIsOpen="tarifModalIsOpen"
      @onUpdated="tarifEdited"
      @onClose="closeModal"
      :tarif="tarifEdit"
      :context="{ ...context, tarif: tarifEdit }"
      :key="tarifEdit.id"
    />
  </div>
  <div v-else>Chargement ...</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tarifModal from "@/components/utils/modals/Tarif/Tarif__Modal.vue";
// import RowTarif from "@/components/Tarifs/Tarifs__ListRow.vue";
import InputSelect from "@/components/utils/select.vue";

import modalConfirm from "@/components/utils/modals/yes-no.vue";

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
export default {
  components: {
    tarifModal,
    // RowTarif,
    modalConfirm,
    InputSelect,
  },

  data: () => ({
    mounted: false,
    // currentTarifs: [],
    tarifModalIsOpen: false,
    tarifsLastSaison: [],
    modalConfirmIsOpen: false,
    isEdit: false,
    tarifEdit: {},
    allTarifs: [],
    saisonSelected: null,
  }),

  async created() {
    await this.getListEtapeValidationTarif();
    this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", [
      "getStructure",
      "currentStructureType",
      "hasCodeFederal",
      "getGroupeCreneaux",
    ]),

    ...mapGetters("saisons", ["getSaison", "currentSaison"]),

    structure() {
      return this.getStructure(this.context.ID_Structure);
    },
    saisons() {
      let saisons = [];
      for (let saison = this.currentSaison.id; saison >= 2019; saison--) {
        saisons = [
          ...saisons,
          { name: `Saison ${saison}-${saison + 1}`, value: saison + 1 },
        ];
      }
      return saisons;
    },
    GroupeCreneaux() {
      const dataFilterBySaison = [
        ...this.getGroupeCreneaux.filter(
          (group) => group.ID_Saison === this.saisonSelected
        ),
      ];
      return dataFilterBySaison;
    },
    headers() {
      if (this.context.ID_Saison === this.saisonSelected) {
        return [
          {
            text: "Nom Tarif",
            value: "NomTarif",
          },
          { text: "Type", value: "type" },
          { text: "Montant", value: "montant" },
          { text: "Statut", value: "EtapeValidationTarif.NomEtape" },
          { text: "Action", align: "center", value: "actions" },
        ];
      } else
        return [
          {
            text: "Nom Tarif",
            value: "NomTarif",
          },
          { text: "Type", value: "type" },
          { text: "Montant", value: "montant" },
          { text: "Statut", value: "EtapeValidationTarif.NomEtape" },
        ];
    },
    SaisonName() {
      return this.getSaison(this.context.ID_Saison).NomSaison;
    },

    canAddTarif() {
      if (this.currentSaison.id == this.context.ID_Saison) return false;

      return true;
    },
    currentTarifs() {
      if (!this.allTarifs) return [];

      const tarifsFilteredBySaison = [
        ...this.allTarifs.filter(
          (tarif) => tarif.ID_Saison == this.saisonSelected
        ),
      ];
      const filteredTarif = tarifsFilteredBySaison.map((tarif) => ({
        ...tarif,
        type: tarif.EST_Objet ? "Option adhésion" : "Tarif d'adhésion",
        montant: `${tarif.MontantUnitaire}€`,
      }));
      return filteredTarif;
    },
  },

  methods: {
    ...mapActions("basics", ["getListEtapeValidationTarif"]),
    ...mapActions("structure", [
      "getAffiliationTarifStructure",
      "updateAffiliationTarifSructure",
      "deleteAffiliationTarif",
      "getGroupeCreneauFullData",
    ]),
    onChangeSaison() {
      this.context.ID_Saison = this.saisonSelected;
    },
    createTarif() {
      this.isEdit = false;
      this.tarifModalIsOpen = true;
    },
    async setData() {
      this.saisonSelected = this.context.ID_Saison;
      let tarifs = await this.getAffiliationTarifStructure(this.context);
      this.allTarifs = tarifs.filter((tarif) => tarif.EtapeValidationTarif);
      this.tarifsLastSaison = [
        ...this.allTarifs.filter(
          (tarif) => tarif.ID_Saison == this.context.ID_Saison - 1
        ),
      ];
      await this.getGroupeCreneauFullData({
        ID_Structure: this.context.ID_Structure,
        ID_Saison: this.saisonSelected,
      });
    },

    openModalConfirm() {
      this.modalConfirmIsOpen = true;
    },

    editTarif(item) {
      this.tarifEdit = { ...item };
      this.isEdit = true;
      this.tarifModalIsOpen = true;
    },
    async deleteTarif(item) {
      const itemOpt = item.id;
      const optionAdh =
        this.GroupeCreneaux &&
        this.GroupeCreneaux.map((option) => option.OptionAdhesion);
      optionAdh &&
        optionAdh.map((opt) => {
          if (opt.includes(itemOpt)) {
            throw error_notification(
              "Tarif associé à un groupe, suppression impossible"
            );
          }
        });
      try {
        await this.deleteAffiliationTarif({
          ID_Tarif: item.id,
          ID_Structure: this.context.ID_Structure,
        });
        this.tarifEdited();
      } catch (e) {
        error_notification("Tarif associé à un groupe, suppression impossible");
      }
    },
    tarifEdited() {
      this.setData();
      this.tarifModalIsOpen = false;
      success_notification("Mis à jour");
    },

    tarifCreated() {
      this.setData();
      this.tarifModalIsOpen = false;
      success_notification("Tarif ajouté");
    },

    tarifDeleted() {
      this.setData();
      success_notification("Tarif supprimé");
    },

    closeModal() {
      this.tarifModalIsOpen = false;
      this.tarifEdit = {};
    },

    async copyTarif() {
      this.modalConfirmIsOpen = false;
      let tarifs = [...this.tarifsLastSaison];
      tarifs.forEach((tarif) => {
        (tarif.ID_Saison = this.context.ID_Saison),
          (tarif.ID_EtapeValidationTarif = 1),
          delete tarif.MKP_FamilleTarif;
        delete tarif.EtapeValidationTarif;
        delete tarif.__typename;
        delete tarif.id;
      });
      await this.updateAffiliationTarifSructure({ tarif: tarifs });
      this.setData();
      success_notification("Mis à jour");
    },
  },

  props: {
    //Context var like ID_Saison, ID_Structure
    context: {
      type: Object,
    },
  },
};
</script>
<style>
.label__saison > .ffme-form__select {
  width: 30%;
}
</style>
